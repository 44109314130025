import { useState } from 'react'
import clsx from 'clsx'
import { Input, Button } from '../../index'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'

import SelectField from './SelectField'
import TextField from './TextField'
import PhoneField from './PhoneField'

const validationSchema = Yup.object({
  'sender-name': Yup.string().required("Please enter your name."),
  'sender-phone': Yup.string().length(12, "Please enter a valid phone number.").required("Please enter your phone number."),
  'sender-email': Yup.string().email("Please enter a valid email.").required("Please enter your email address."),
  reason: Yup.string(),
  discovery: Yup.string(),
  comment: Yup.string(),
})

const initialValues = {
  'sender-name': '',
  'sender-phone': '',
  'sender-email': '',
  reason: '',
  discovery: '',
  comment: '',
}

const endpoint = process.env.NEXT_PUBLIC_CF7_ENDPOINT
const formId = process.env.NEXT_PUBLIC_CF7_FORM_ID

const reasonOptions = [
  "Remodel/addition",
  "New construction",
  "Employment",
  "Other inquiry",
].map(option => ({ value: option, label: option }))

const messageClassName = 'absolute mt-20 text-xs-B -tracking-3 md:mt-20 md:text-2xl-C'
const internalServerErrorMessage = 'There was an error. Please try again later.'

const ContactForm = ({ className = '' }) => {
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData()
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    })
    formData.append('_wpcf7_unit_tag', `wpcf7-f${formId}-o1`)

    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow',
    }

    try {
      const response = await fetch(endpoint, requestOptions)
      const result = await response.json()

      if (result.status == 'mail_sent') {
        resetForm()
        setSuccessMessage(result.message)
      } else {
        setErrorMessage(result.message)
      }
    } catch (error) {
      setErrorMessage(internalServerErrorMessage)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form
          className={`${className} w-full grid grid-cols-1 md:grid-cols-2 md:gap-x-50 gap-y-20 md:gap-y-40`}
        >
          <TextField name="sender-name" label="Name *" />
          <PhoneField name="sender-phone" label="Phone Number *" />
          <TextField name="sender-email" label="Email Address *" />
          <SelectField
            name="reason"
            label="What are you inquiring about?"
            placeholder=""
            isSearchable={false}
            options={reasonOptions}
          />
          <TextField textarea name="discovery" label="How did you find out about us?" />
          <TextField textarea name="comment" label="Have a comment?" />

          <div className='mt-15 md:row-start-4 md:mt-0'>
            <Button
              type="submit"
              text='Send Message'
              icon={isSubmitting ? 'loader' : 'arrow'}
            />

            {errorMessage && (
              <p className={clsx(messageClassName, 'text-red-500')}>
                {errorMessage}
              </p>
            )}

            {successMessage && (
              <p className={messageClassName}>
                {successMessage}
              </p>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
