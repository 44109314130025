import clsx from 'clsx'
import { Icon, Link } from '../index'
import Styles from './Button.module.scss'

const Button = ({
  text,
  href,
  target = '_self',
  name = '',
  buttonClickHandler = () => {},
  className = '',
  buttonColor = 'primary',
  icon = 'arrow', // Arrow-right by default
  fullWidthMobile = false,
  disabled = false,
  borderClassName = '',
  type="submit",
}) => {
  const isBlankTarget = target == '_blank'
  const buttonPrimary = buttonColor === 'primary'

  return (
    <div
      className={clsx(
        !disabled && Styles.button, // hover animation
        'inline-flex text-base-A ',
        'border',
        // Primary
        buttonPrimary && 'hover:text-white',
        buttonPrimary && !disabled && 'text-secondary',
        // Seconday button
        buttonColor === 'secondary' ? 'hover:text-primary' : (!buttonPrimary && 'hover:text-secondary'), 
        buttonColor === 'secondary' ? 'text-primary' : 'text-white',
        !buttonPrimary && buttonColor !== 'none' && !disabled && Styles.BgTransparent,
        //
        buttonColor === 'none' && 'hover:text-secondary',
        buttonColor === 'none' && !disabled && Styles.BgTransparent,
        fullWidthMobile && 'w-full justify-center md:w-auto',
        buttonColor === 'secondary' ? 'border-primary-light' : 'border-white'
      )}>
      {href ? (
        <Link href={href} prefetch={false}>
          <a
            className={`inline-flex w-full justify-center items-center px-20 py-15 focus:outline-none focus-visible:outline-black ${className}`}
            target={target}
            rel={isBlankTarget ? 'noopener noreferrer' : ''}>
            {text}
            <Icon className='ml-12' borderClassName={borderClassName} name={icon} />
          </a>
        </Link>
      ) : (
        <button
          disabled={disabled}
          name={name}
          type={type}
          className={`inline-flex w-full justify-center items-center px-20 py-15 focus:outline-none focus-visible:outline-black ${className}`}
          onClick={buttonClickHandler}>
          {text}
          <Icon className='ml-12' borderClassName={borderClassName} name={icon} />
        </button>
      )}
    </div>
  )
}

export default Button
