import 'react-phone-number-input/style.css'
import clsx from 'clsx'
import PhoneInput from 'react-phone-number-input/input'
import { useField } from 'formik'


const PhoneField = ({ name, label }) => {
  const [field, meta, helpers] = useField({ name })

  return (
    <div>
        <div className={`text-xs-B md:text-base`}>
        <label className='block mb-10 -tracking-3 uppercase md:mb-16' htmlFor={field.name}>
            {label}
        </label>
        <PhoneInput
          className={clsx(
            `border-white-500 p-8 w-full h-48 text-base bg-secondary border`,
            `focus:border-transparent rounded-none focus:outline-none appearance-none focus:ring-1 focus:ring-primary`,
            (meta.touched && meta.error) && 'border-red-500'
          )}
            country="US"
            name={field.name}
            value={field.value}
            onChange={helpers.setValue}
        />
        </div>
        {meta.touched && meta.error && (
        <div className="mt-6">{meta.error}</div>
        )}
    </div>
  );
}

export default PhoneField;
