import clsx from 'clsx'

const Input = props => {
  const { name, label, textarea, invalid, className = '' } = props

  // Filter invalid properties - WPO
  let invalidProps = ['label', 'invalid', 'textarea']
  textarea && invalidProps.push('type')

  const validProps = Object.keys(props)
    .filter(key => !invalidProps.includes(key))
    .reduce((obj, key) => {
      obj[key] = props[key]
      return obj
    }, {})

  return (
    <div className={`${className} text-xs-B md:text-base`}>
      <label className='block mb-10 -tracking-3 uppercase md:mb-16' htmlFor={name}>
        {label}
      </label>
      {!!textarea ? (
        <textarea
          {...validProps}
          className={clsx(
            'border-white-500 p-8 w-full h-250 text-base bg-secondary border focus:border-transparent rounded-none focus:outline-none appearance-none focus:ring-1 focus:ring-primary md:h-380',
            invalid && 'border-red-500'
          )}
        />
      ) : (
        <input
          {...validProps}
          className={clsx(
            `border-white-500 p-8 w-full h-48 text-base bg-secondary border`,
            `focus:border-transparent rounded-none focus:outline-none appearance-none focus:ring-1 focus:ring-primary`,
            invalid && 'border-red-500'
          )}
        />
      )}
    </div>
  )
}

export default Input
