import Select from 'react-select'
import { useField } from 'formik'

const colors = {
  primary: '#cda873',
  secondary: '#202020',
  white: '#fff',
}

const selectStyles = {
  control: (baseStyles, { isFocused }) => ({
    ...baseStyles,
    borderColor: isFocused ? colors.primary : colors.white,
    backgroundColor: colors.secondary,
    borderRadius: 0,
    height: '4.8rem',
    minHeight: '4.8rem',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    }
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    height: '4.8rem',
    padding: '0 6px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (baseStyles, { isFocused }) => ({
    ...baseStyles,
    padding: '0 6px',
    color: isFocused ? colors.primary : colors.white,
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: colors.primary,
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    color: colors.primary,
    backgroundColor: colors.secondary,
    height: '4.8rem',
    lineHeight: '4.8rem',
    padding: '0 1.8rem',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      backgroundColor: 'inherit',
    }
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 0,
    backgroundColor: colors.secondary,
    margin: 0,
  })
}

const SelectField = ({ name, label, placeholder, isSearchable, options }) => {
  const [ field, meta, helpers ] = useField({ name });

  return (
    <div>
      <div className={`text-xs-B md:text-base`}>
        <label className='block mb-10 -tracking-3 uppercase md:mb-16' htmlFor={field.name}>
          {label}
        </label>
        <Select
          styles={selectStyles}
          name={field.name}
          onChange={({ value }) => helpers.setValue(value)}
          placeholder={placeholder}
          isSearchable={isSearchable}
          options={options}
        />
      </div>
      {meta.touched && meta.error && (
        <div className="mt-6">{meta.error}</div>
      )}
    </div>
  );
}

export default SelectField
