import clsx from 'clsx'
import Image from 'next/legacy/image'
import { Cta, SubHeading, Paragraph } from '../index'
import { IMG_LAZY_LOAD_THRESHOLD } from '../../utils/siteConfig'
import { gsap } from 'gsap'
import React, { useState, useEffect, useRef } from 'react'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Heading,
  HeroText,
  Container,
  GridContainer,
  SectionContainer,
} from '../index'
import Styles from './ThreeColContact.module.scss'
import useIntroEnded from '../../hooks/useIntroEnded'

const ContactCards = ({ className, index, modalIndex, contact }) => {
  const tl = useRef()
  const containerRef = useRef()
  const q = gsap.utils.selector(containerRef)

  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        tl.current = gsap.set(q('.contactCard'), { yPercent: 20, opacity: 0 })
        tl.current = gsap
          .timeline({
            defaults: { duration: 1, ease: 'bigd-ease-out' },
            paused: true,
          })
          .addLabel('init')
          .to(q('.contactCard'), { opacity: 1, duration: 0.25 }, `init+=${modalIndex * '.15'}}`)
          .to(q('.contactCard'), { yPercent: 0 }, `init+=${modalIndex * '.15'}}`)

        ScrollTrigger.create({
          trigger: containerRef?.current,
          start: '0 90%',
          onEnter: () => {
            tl?.current?.play()
          },
        })
      },
    })
  }, [])

  const name = contact?.title
  const contactPersonImage = contact?.contact?.image
  const contactPersonName = contact?.contact?.name
  const contactPersonTitle = contact?.contact?.position
  const contactPersonOffice = contact?.contact?.office
  const contactPersonCell = contact?.contact?.phone

  return (
    <div key={index} ref={containerRef} className={`overflow-hidden py-40 md:py-0 ${className}`}>
      <div className={'contactCard'}>
        <Heading
          semantics='h2'
          text={name}
          className='tracking-4 mb-20 text-primary text-2xl-F uppercase md:mb-32 md:text-4xl-B'
        />

        <div className='flex items-start mt-30 space-x-20'>
          <figure className={clsx('w-1/2 leading-3', Styles.contact_profile)}>
            <Image
              className='w-full h-auto object-cover'
              src={contactPersonImage?.sourceUrl}
              alt={contactPersonImage?.altText}
              width={184 * 2}
              height={250 * 2}
              objectFit='cover'
              sizes='(max-width: 768px) 100vw, 50vw'
              lazyBoundary={IMG_LAZY_LOAD_THRESHOLD}
            />
          </figure>

          <div className='flex flex-col text-xs-B -tracking-3 md:text-base'>
            <div>{contactPersonName}</div>
            <div>{contactPersonTitle}</div>
            <div>{contactPersonOffice}</div>
            <div>
              Cell: <a href={`tel:+1${contactPersonCell}`}>{contactPersonCell}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ThreeColContact = ({
  heroHeading,
  contactA,
  contactB,

  contactInfoHeading,
  contactInfo,

  cta,
}) => {
  const tl = useRef()
  const containerRef = useRef()
  const { introEnded } = useIntroEnded()

  useEffect(() => {
    tl.current = gsap.set(containerRef?.current, { opacity: 0, yPercent: 10 })
  }, [])

  useEffect(() => {
    if (introEnded) {
      tl.current = gsap
        .timeline({ defaults: { duration: 1, ease: 'bigd-ease-out' } })
        .addLabel('init')
        .to(containerRef?.current, { opacity: 1, duration: 0.25 }, 'init+=.8')
        .to(containerRef?.current, { yPercent: 0 }, 'init+=.8')
    }
  }, [introEnded])

  const borderClassNames = 'border-b border-r border-r-0 border-primary-light md:border-t-0 md:border-r md:border-b-0'

  return (
    <SectionContainer className='hero-pt md:mb-170'>
      <Container>
        <div className='flex justify-center mb-20 md:mb-130'>
          <HeroText
            text={heroHeading}
            className='tracking-4 text-white text-6xl uppercase md:text-9xl'
          />
        </div>

        <GridContainer className='gap-y-0'>
          {contactA &&
            <ContactCards
              className={clsx(
                'col-span-4 text-white md:col-span-3',
                borderClassNames,
              )}
              index='A'
              modalIndex={0}
              contact={contactA}
            />}
          {contactB &&
            <ContactCards
              className={clsx(
                'col-span-4 items-start justify-start text-white md:col-span-3',
                borderClassNames,
              )}
              index='B'
              modalIndex={1}
              contact={contactB}
            />}
          {contactInfo &&
           <div className={clsx(
             'col-span-4 md:col-span-3 py-40 md:py-0',
           )}>
             <SubHeading text={contactInfoHeading} className={clsx('mb-40', 'md:mb-55')} onScrollAnimation={true} />
             <Paragraph
               overrideClassName='text-base-A md:text-base paragraph text-white'
               text={contactInfo}
             />
             <Cta cta={cta} className='mt-40 hidden md:inline-block' />
           </div>}
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default ThreeColContact
