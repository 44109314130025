import { Input } from '../../index'
import { Field } from 'formik'

const TextField = ({ name, label, textarea = false }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <div>
          <Input
            label={label}
            name={field.name}
            type='text'
            value={field.value}
            onChange={field.onChange}
            invalid={meta.touched && meta.error}
            textarea={textarea}
          />
          {meta.touched && meta.error && (
            <div className="mt-6">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
}

export default TextField;
