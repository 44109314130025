import clsx from 'clsx'
import React, { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import ContactForm from '../forms/contactForm/ContactForm';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

import {
  Container,
  SubHeading,
  CustomImage,
  GridContainer,
  SectionContainer,
} from '../index'
import useLengthOfLongestWord from '../../hooks/useLengthOfLongestWord'

const ContactFormSection = ({
  cta,
  image,
  heading,
  subHeading,
}) => {
  const imageRef = useRef()
  const q = gsap.utils.selector(imageRef)
  const tl = useRef()

  useEffect(() => {
    imageRef?.current && (tl.current = gsap.set(q('figure'), { scale: 1.1 }))

    imageRef?.current &&
      (tl.current = gsap
        .timeline({
          defaults: { duration: 1, ease: 'bigd-ease-out' },
          scrollTrigger: {
            trigger: imageRef?.current,
            start: '30% 100%',
          },
        })
        .to(q('figure'), { scale: 1 }))
  }, [])

  return (
    <SectionContainer className={clsx('pt-80 bg-background md:pt-0')}>
      <Container className='py-0'>
        <GridContainer className={clsx('grid-flow-row items-center md:grid-flow-col md:py-150')}>
          <ImageComponent
            image={image}
            imageRef={imageRef}
          />

          <CopyComponent
            cta={cta}
            heading={heading}
            subHeading={subHeading}
          />
        </GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default React.memo(ContactFormSection)

const ImageComponent = ({
  image,
  imageRef,
}) => {
  return (
    <div
      className={clsx(
        'relative col-span-4 mt-80 -ml-16 -mr-16 h-full overflow-hidden md:mr-0 md:mt-0',
        'md:col-start-0 md:-ml-50 md:mr-0',
        'flex items-center justify-center cursor-pointer flex-col',
      )}
      ref={imageRef}
    >
      <CustomImage
        src={image?.sourceUrl}
        alt={image?.altText}
        className='w-full h-full object-cover object-center'
        sizes='(max-width: 768px) 100vw, 50vw'
        aspectRatio='aspect-w-3 aspect-h-5 md:aspect-w-4 md:aspect-h-5'
      />
    </div>
  )
}

const CopyComponent = ({ cta, heading, subHeading, description }) => {
  const hLengthMax = useLengthOfLongestWord(heading)

  return (
    <div
      className={clsx(
        'flex col-span-6 items-center self-stretch md:col-span-4.5',
        'row-start-1 ',
        'md:col-start-4.5'
      )}>
      <div className="w-full">
        <SubHeading text={subHeading} className={clsx('mb-40', 'md:mb-55')} onScrollAnimation={true} />

        <div className={clsx('flex items-start flex-col mb-10')}>
          <ContactForm />
        </div>
      </div>
    </div>
  )
}
